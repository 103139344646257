<template>
	<div class="container">
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>审批中心</el-breadcrumb-item>
			<el-breadcrumb-item>发起申请</el-breadcrumb-item>
		</el-breadcrumb>
		<el-collapse v-model="actives" style="border-radius: 5px">
			<el-collapse-item
				v-for="(group, index) in groups"
				:key="index"
				:title="group.name"
				:name="group.name"
				v-show="group.templates.length > 0"
			>
				<div>
					<div
						v-for="(template, index) in group.templates || []"
						:key="index"
						class="form-item"
						@click="start(template)"
					>
						<div>
							<ellipsis hover-tip :content="template.name" />
							<span>发起申请</span>
						</div>
					</div>
				</div>
			</el-collapse-item>
		</el-collapse>
		<el-drawer
			size="100%"
			:visible.sync="show"
			:with-header="false"
			:before-close="handleClose"
		>
			<el-container class="main-container">
				<el-header class="drawer-header">
					<div>启动流程设置</div>
					<div>
						<el-button
							@click=";(show = false), (loading = false)"
							size="mini"
						>
							取 消
						</el-button>
						<el-button
							type="primary"
							@click="submit"
							:loading="loading"
							size="mini"
						>
							{{ loading ? '提交中 ...' : '确 定' }}
						</el-button>
					</div>
				</el-header>
				<el-container class="drawer-body">
					<el-aside width="400px" style="padding: 0 20px">
						<el-form :model="form">
							<el-form-item label="模板ID">
								<el-input
									v-model="form.templateId"
									autocomplete="off"
									readonly=""
								></el-input>
							</el-form-item>
							<el-form-item label="申请人">
								<el-input
									v-model="form.nickname"
									autocomplete="off"
									readonly=""
								></el-input>
							</el-form-item>
							<el-form-item label="业务唯一字段">
								<el-input
									v-model="form.unique"
									autocomplete="off"
								></el-input>
							</el-form-item>
							<el-form-item label="业务扩展字段">
								<el-input
									type="textarea"
									:rows="2"
									v-model="form.attach"
									autocomplete="off"
								></el-input>
							</el-form-item>
							<el-alert
								:title="'当前流程有' + nodeIds.length + '个节点需要设置审批人'"
								type="error"
								:closable="false"
								v-if="nodeIds.length > 0 && auditors.length == 0"
							></el-alert>
							<el-descriptions
								title="设置节点审批人"
								:column="1"
								v-if="nodeIds.length > 0 && auditors.length > 0"
							>
								<el-descriptions-item
									:label="desc.label"
									v-for="(desc, index) in descs"
									:key="index"
								>
									{{ desc.value }}
								</el-descriptions-item>
							</el-descriptions>
						</el-form>
					</el-aside>
					<el-main>
						<div style="background: #fff">
							<process-diagram-viewer
								mode="setting"
								@selectedNode="nodeSelected"
							/>
						</div>
					</el-main>
				</el-container>
			</el-container>
		</el-drawer>
		<user-picker
			ref="userPicker"
			:selected="selects"
			:multiple="multiple"
			@ok="selected"
		></user-picker>
	</div>
</template>

<script>
import UserPicker from '../components/UserPicker'
import ProcessDiagramViewer from '../common/ProcessDiagramViewer'
export default {
	name: 'workSpace',
	components: { UserPicker, ProcessDiagramViewer },
	data() {
		return {
			actives: [],
			groups: [],
			selectNodeId: '',
			selects: [],
			multiple: true,
			show: false,
			loading: false,
			nodeIds: [],
			auditors: [],
			descs: [],
			form: {
				templateId: '',
				operator: '',
				nickname: '',
				auditors: {},
				unique: this.$format.dateFormat(
					new Date(),
					'yyyyMMddHHmmss'
				),
				attach: ''
			}
		}
	},
	mounted() {
		this.listGroups()
	},
	methods: {
		async listGroups() {
			this.groups = await this.$api.flowable.listGroupsWithTemplate()
			this.groups.forEach(group => {
				this.actives.push(group.name)
			})
		},
		async start(template) {
			// 查询是否有自选审批人
			this.nodeIds =
				(await this.$api.flowable.listNeedSelfSelectNodes({
					templateId: template.id
				})) || []
			this.form.templateId = template.id
			this.form.operator = sessionStorage.getItem('operator')
			this.form.nickname = sessionStorage.getItem('nickname')
			const copy = this.$utils.deepCopy(template)
			copy.process = JSON.parse(copy.process)
			this.$store.state.flowable.template = copy
			this.$store.state.flowable.finishedNodeIds = []
			this.$store.state.flowable.runningNodeIds = []
			this.$store.state.flowable.waitingNodeIds = []
			this.show = true
		},
		nodeSelected(nodeInfo) {
			if (
				nodeInfo.type == 'APPROVAL' &&
				nodeInfo.props.assignedType == 'SELF_SELECT'
			) {
				this.selectNodeId = nodeInfo.id
				const auditor = this.auditors.find(
					item => item.id == this.selectNodeId
				)
				if (!auditor) {
					this.auditors.push({
						id: nodeInfo.id,
						name: nodeInfo.name,
						users: []
					})
				}
				this.multiple = nodeInfo.props.selfSelect.multiple
				this.$refs.userPicker.show()
			}
		},
		selected(selects) {
			this.selects = selects
			const auditors = this.auditors.map(item => {
				if (item.id == this.selectNodeId) {
					item.users = selects
				}
				return item
			})
			this.auditors = auditors
			this.transfer()
		},
		transfer() {
			const descs = []
			this.auditors.forEach(item => {
				descs.push({
					label: '节点名',
					value: item.name
				})
				descs.push({
					label: '审批人',
					value: item.users.map(it => it.nickname).join(', ')
				})
			})
			this.descs = descs
		},
		handleClose(done) {
			if (this.loading) {
				return
			}
			this.$confirm('确定要提交表单吗？')
				.then(_ => {
					this.submit()
				})
				.catch(() => {
					this.loading = false
					this.show = false
				})
		},
		submit() {
			let confirm = this.nodeIds.length > 0
			if (confirm && this.auditors.length > 0) {
				let auditors = new Map()
				this.auditors.forEach(item => {
					if (item.users.length > 0) {
						const value = item.users.map(x => x.id).join(',')
						auditors.set(item.id, value)
					}
				})
				confirm = this.nodeIds.length > auditors.size
				this.form.auditors = Object.fromEntries(auditors.entries())
			}
			if (confirm) {
				this.$confirm('还有节点未设置审批人，是否确认提交？').then(
					_ => {
						this.loading = true
						this.$api.flowable.start(this.form).then(() => {
							this.$message.success('提交成功')
							this.loading = false
							this.show = false
						})
					}
				).catch(() => {
				})
			} else {
				this.loading = true
				this.$api.flowable.start(this.form).then(() => {
					this.$message.success('提交成功')
					this.loading = false
					this.show = false
				})
			}
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	.breadcrumb {
		margin-bottom: 10px;
	}
	.no-data {
		text-align: center;
		padding: 50px 0;
		color: #656565;
		margin: 0 auto;
	}

	/deep/ .el-collapse {
		padding: 0 15px;
		background: #ffffff;

		.el-collapse-item__header {
			font-size: medium;
		}

		.el-collapse-item__wrap {
			padding: 20px 10px;
		}

		.el-tabs--border-card .el-tabs__content {
			padding: 40px 15px;
		}
	}
	.form-item {
		padding: 15px 10px;
		width: 200px;
		cursor: pointer;
		border: 1px solid #d9dada;
		border-radius: 5px;
		float: left;
		margin: 5px 10px;
		height: 37px;
		&:hover {
			border: 1px solid #448ed7;
			span {
				display: inline-block !important;
			}
		}
		i {
			padding: 8px;
			border-radius: 8px;
			float: left;
			font-size: 20px;
			color: #ffffff;
			background: #38adff;
			height: 20px;
			line-height: 20px;
		}
		div {
			height: 35px;
			line-height: 35px;

			div {
				display: inline-block;
				margin-left: 10px;
				width: 100px;
			}
			span {
				display: none;
				float: right;
				color: #38adff;
				font-size: 12px;
			}
		}
	}
}
.main-container {
	height: calc(100% - 80px);
	.drawer-header {
		background-color: #fff;
		color: #333;
		line-height: 60px;
		width: 100%;
		overflow: hidden;
		border-bottom: 1px solid #ddd;
		box-shadow: 0 1px 4px #00152914;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		z-index: 1000;
	}
	.drawer-body {
		margin-top: 80px;
		height: 100%;
		.el-main {
			padding: 0 20px;
		}
		.desc-info {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}
@media screen and (max-width: 800px) {
	.form-item {
		padding: 12px 10px !important;
		width: 150px !important;
		margin: 5px !important;

		&:hover {
			span:last-child {
				display: none !important;
			}
		}
	}
}
</style>
